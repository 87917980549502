
import React from "react";
import { PopupWidget } from "react-calendly";

const StickyVisitBtn = () => {
  return (
    <div className="calendly-container">
      <div className="calendly-badge-widget">

      <PopupWidget
        url="https://calendly.com/dr_mayank_dabral/consultation-with-dr-mayank-dabral?hide_event_type_details=1&hide_gdpr_banner=1&text_color=5c483f"
        rootElement={document.getElementById("root")}
        text="Schedule Clinic Visit"
        textColor="#ffffff"
        color="#396CF0"
        />
        </div>
    </div>
  );
};

export default StickyVisitBtn



