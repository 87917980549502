import React from 'react';
import Navbar from "../components/navbar";
import Banner from "../components/banner";
import hero2 from '../assets/images/heroServices.svg';
import CurveSlight from "../components/features/curveSlight";
import ServicesSpanshot from '../components/servicesSpanshot';
import CtaOne from '../components/cta/ctaOne';
import CtaTwo from '../components/cta/ctaTwo';
import ServicesOne from '../components/features/servicesOne';
import Contact from './contact';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';

function Services() {
  return (
    <>
    <Navbar manuClass="navigation-menu nav-light nav-right" containerClass="container"/>
    <section>
                <Banner 
                    bgImage={hero2}
                    title="Discover Our Diverse Range of Care Services"
                    description="Tailored Solutions for Your Health and Well-being"
                    overlayClassName="bg-overlay"
                    titleClassName=""
                    height={80}
                />
                <CurveSlight/>
    </section>
    <ServicesSpanshot />
    <CtaOne/>
    <ServicesOne />
    <CtaTwo/>
    <div className='pt-5'></div>
    <Contact />
    <Footer />
    <ScrollTop />
    </>
  )
}

export default Services