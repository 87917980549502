import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import hero2 from '../../assets/images/hero2.webp';
import Navbar from "../../components/navbar";
import CtaTwo from "../../components/cta/ctaTwo";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import Review from "../review";
import Contact from "../contact";
import Banner from "../../components/banner";
import Youtube from "../youtube";
import CtaOne from "../../components/cta/ctaOne";
import HeroSection from "../../components/heroSection";
import CurveSlight from "../../components/features/curveSlight";
import AboutSmall from "../../components/aboutSmall";
import ServicesSpanshot from "../../components/servicesSpanshot";
import FaqSection from "../../components/faqSection";
import { Helmet } from "react-helmet";

const Index = () => {
  // console.log("Home page is rendering"); // Debugging statement
  
  const { section } = useParams(); // Get the route parameter
  const sectionRef = useRef(null);
  
  useEffect(() => {
    // console.log("useEffect is triggered");

    if (section) {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        // console.log(`Section ${section} is found`); 

        if (section === "online-appointment") {
          // console.log("Scrolling to online-appointment section"); 

          const sectionHeight = sectionElement.offsetHeight;
          const scrollPosition = sectionElement.offsetTop + sectionHeight / 2 - 100;
          window.scrollTo({
            top: scrollPosition,
            behavior: "smooth"
          });
        } else {
          // console.log(`Scrolling to ${section} section`);

          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // console.log(`Section ${section} not found`); 
      }
    } else {
      // console.log("No section specified"); 

      window.scrollTo(0, 0); 
    }
  }, [section]);

  return (
    <>
      <Helmet>
        <title>Chirayu Wellness Center | Doctor & General Physician in Dehradun</title>
        <meta name="description" content="Best General Physician in Dehradun | Dr Mayank Dabral"/>
        <meta property="og:title" content="Chirayu Wellness Center | Your Trusted General Physician in Dehradun"/>
        <meta property="og:description" content="Doctor & General Physician in Dehradun | Dr Mayank Dabral"/>
        <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
        <meta property="og:url" content="https://drmayankdabral.com/"/>
      </Helmet>
      <section id='navbar' >
        <Navbar navDark={true} manuClass="navigation-menu nav-right" containerClass="container"/>
      </section>
      <section id='index'>
        <HeroSection />
      </section>
      <section id='review'>
        <Review />
      </section>
      <section id="online-appointment" ref={sectionRef}>
        <CtaOne/>
      </section>
      <section id='banner' style={{ marginTop: '-10%' }}>
        <Banner 
          bgImage={hero2}
          title="Best General Physician in Dehradun"
          description="Providing the support and resources needed for individuals to make informed decisions that lead to improvedwell-being and vitality."
          overlayClassName=""
          titleClassName="bg-50"
          height={80}
        />
        <CurveSlight/>
      </section>
      <section id="aboutbrief">
        <AboutSmall />
        <CtaTwo/>
        <ServicesSpanshot />
        <Youtube/>
        <FaqSection />
      </section>
      <section id ="contact" ref={sectionRef}>
        <Contact />
      </section>
      <section id="footer">
        <Footer/>
        <ScrollTop/>
      </section>
    </>
  );
};

export default Index;
