import React from 'react'
import { useEffect } from 'react';
import Lottie from "lottie-react";
import infoAnimation from "../assets/images/infoAnimation.json";




function Youtube() {

    // for inserting youtube channel from elfsight start
    useEffect(() => {
        const script = document.createElement('script');
        script.src="https://static.elfsight.com/platform/platform.js";

        script.setAttribute('data-use-service-core', '');
        script.defer = true;
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    //   for inserting youtube channel from elfsight end
      
    return (

        <>

            <section className="section" style={{background: 'var(--light-purple)'}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title">
                            <span className="badge bg-soft-primary p-3 fs-6">Knowledge Center</span>
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-sm-7">
                                <h3 className="heading mt-3">Explore Our Knowledge Center</h3>
                                <h4 className="heading mb-3">Be The Preventive Doctor</h4>
                                <p className='para-desc text-muted'>Explore our curated collection of informative videos from our YouTube channel, focusing on preventive health, lifestyle changes, and holistic well-being. Gain valuable insights into disease prevention, healthy living tips, and innovative approaches to healthcare. Stay informed and empowered on your journey to optimal health.</p>
                            </div>
                            <div className="col-12 col-sm-5 mb-sm-0 text-center">
                                <Lottie animationData={infoAnimation} className='youtubeAnimation' speed='2.0' />
                            </div>
                        </div>
                        <div className="elfsight-app-aad74c98-9089-428c-837c-9ae05841f697" data-elfsight-app-lazy></div>
                    </div>
                </div>




            </section>
        </>

    )
}

export default Youtube