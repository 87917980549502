
import React from 'react';

const Banner = ({ bgImage, title, description, overlayClassName, titleClassName }) => {
  return (
    <section className="bg-half-170 d-table w-100" style={{backgroundImage: `url(${bgImage})`, height: `auto`, backgroundRepeat: 'no-repeat' }}>
      <div className={`bg-overlay-dark ${overlayClassName}`}></div>
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-xl-10">
            <div className={`heading-title text-center ${titleClassName}`}>
              <h2 className="heading fw-bold text-white title-dark mt-3 mb-4">{title}</h2>
              <h3 className="mx-auto text-white-50 mb-0">{description}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;