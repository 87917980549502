import React from "react";
import { Link } from "react-router-dom";

// import bg1 from '../assets/images/bg/03.jpg'
// import about from '../assets/images/about/about-2.png'
// import Navbar from "../components/navbar";
import { contact } from "../data/data";
// import Footer from "../components/footer";
// import ScrollTop from "../components/scrollTop";

export default function Contact(){
    return(
        <>
        <section className="row justify-content-center mt-60 mt-1 mb-5">
            <div className="container">
                <div className="row col-12 text-center">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                            <p className="text-muted para-desc mx-auto px-4">We're here to help! Reach out to us for expert guidance and personalized assistance.</p>
                    {contact.map((item,index) =>{
                        let Icon = item.icon
                        return(
                            <div className="col-lg-4 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0" key={index}>
                                <div className="card features feature-primary text-center border-0">
                                    <div className="icon text-center rounded-md mx-auto">
                                        <Icon className="h3 mb-0"/>
                                    </div>
                                    <div className="card-body p-0 mt-3">
                                        <h5>{item.title}</h5>
                                        <Link to={item.link} className="link" target="_blank">{item.name}</Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col p-2">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d730.2983998933731!2d78.01294280528256!3d30.333444864855515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092bd2848ef669%3A0x3ec03e6db1f478df!2sDr.%20Mayank%20Dabral!5e0!3m2!1sen!2sin!4v1709369436913!5m2!1sen!2sin" style={{border:'0'}} title="myfram" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        </>
    )
}