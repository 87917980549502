import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";

import { MdOutlineLocalPrintshop} from '../assets/icons/vander'
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
// import { Button } from "bootstrap";

export default function Privacy(){
    return(
        <>
        <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container"/>
        <section className="bg-half-150 d-table w-100 bg-light">
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h3 className="sub-title mb-4">Privacy policy</h3>
                            <p className="para-desc mx-auto text-muted">Thank you for visiting our website. This Privacy Policy outlines how we handle your personal information when you visit our site.</p>
                            
                            <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                <ul className="breadcrumb bg-transparent rounded mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Doctris</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Privacy</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="card shadow rounded border-0">
                            <div className="card-body">
                                <h5 className="card-title">Personal Information Collection :</h5>
                                <p className="text-muted">We do not collect any personal information from visitors to our website.</p>                            
                                <h5 className="card-title">Use of Cookies :</h5>
                                <p className="text-muted">We do not use cookies or any other tracking technologies on our website.</p>                            
                                <h5 className="card-title">Third-Party Links :</h5>
                                <p className="text-muted">Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>                            
                                <h5 className="card-title">Children's Privacy :</h5>
                                <p className="text-muted">Our website is not directed towards children under the age of 13. We do not knowingly collect personal information from children.</p>                            
                                <h5 className="card-title">Policy Updates :</h5>
                                <p className="text-muted">We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page.</p>                            
                                <h5 className="card-title">Contact Us :</h5>
                                <p className="text-muted">If you have any questions or concerns about our Privacy Policy, please contact us at info@chirayumedicalassure.com.</p>                            
                                <p className="text-muted">This Privacy Policy is effective as of 01st March 2024.</p>                            
                                {/* <ul className="list-unstyled text-muted">
                                    <li><FiArrowRight className="fea icon-sm me-2 mb-0"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="mt-2 ms-0"><FiArrowRight className="fea icon-sm me-2 mb-0"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="mt-2 ms-0"><FiArrowRight className="fea icon-sm me-2 mb-0"/>Create your own skin to match your brand</li>
                                    <li className="mt-2 ms-0"><FiArrowRight className="fea icon-sm me-2 mb-0"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="mt-2 ms-0"><FiArrowRight className="fea icon-sm me-2 mb-0"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="mt-2 ms-0"><FiArrowRight className="fea icon-sm me-2 mb-0"/>Create your own skin to match your brand</li>
                                </ul> */}
    
                                {/* <h5 className="card-title">Information Provided Voluntarily :</h5>
                                <p className="text-muted">In the 1960s, the text suddenly became known beyond the professional circle of typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as PageMaker etc.</p> */}
    
                                <Link to="" role="button" tabIndex="0" onClick={() => window.print()} className="btn btn-soft-primary d-print-none"><MdOutlineLocalPrintshop  className="mb-0"/> Print</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}