import React from 'react'
import Banner from "../components/banner";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import rehabService from '../assets/images/banner/rehabServices.webp';
import holisticApproach from '../assets/images/banner/holisticApproach.webp';
import Contact from './contact';
import { Helmet } from "react-helmet";
import CtaOne from '../components/cta/ctaOne';


function Rehab() {
  return (
    <>
    <Helmet>
      <title>Rehab & Palliative Solutions | Comprehensive Healing | Chirayu Wellness Center</title>
      <meta name="description" content="Experience holistic care with our Rehab & Comfort for terminally ill patients, tailored to enhance recovery and comfort."/>
      <meta property="og:title" content="Rehab & Palliative Solutions | Comprehensive Healing | Chirayu Wellness Center"/>
      <meta property="og:description" content="Experience holistic care with our Rehab & Comfort for terminally ill patients, tailored to enhance recovery and comfort."/>
      <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
      <meta property="og:url" content="https://drmayankdabral.com/rehab-palliation"/>
    </Helmet>
     <Navbar manuClass="navigation-menu nav-right nav-light" containerClass="container"/>
    <Banner 
        bgImage={rehabService}
        title="Restoring Wellness: Comprehensive Rehab & Palliative Solutions"
        description="Expert Guidance Through Every Step of the Recovery Journey"
     />
            <section className="bg-hero pb-0 d-table w-100" style={{marginBottom: '80px'}}>
            <div className="container">
                <div className="row mt-sm-0 align-items-center">
                    <div className="col-md-6">
                        <div className="heading-title">
                            <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h4 className="heading mb-3" style={{color: '#5C483F'}}>Holistic Approach to Healing</h4>
                            <p className="para-desc text-muted mb-0 fs-5">Welcome to our Rehab & Palliation Services, where we provide holistic care to enhance recovery and comfort. Our comprehensive approach addresses physical, emotional, and psychological needs, supporting patients and their families through every step of the journey. With expert guidance and personalized plans, we aim to restore wellness and improve quality of life.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={holisticApproach} className="img-fluid" alt=""/>
                    </div>
                </div>
                    <div className="col-12 mt-100">
                    <h4 className="mb-3" style={{color: '#5C483F'}}>Our Key features</h4>
                    <p className="para-desc text-muted mb-0 fs-5">
                        <ul>
                            <li>Holistic approach to rehabilitation and palliative care, focusing on physical, emotional, and psychological well-being.</li>
                            <li>Comprehensive services tailored to individual needs, including physical therapy, pain management, counseling, and spiritual support.</li>
                            <li>Family-centered care and support, ensuring that patients and their loved ones are involved and informed throughout the recovery process.</li>
                            <li>Expert guidance from our experienced team, providing personalized care plans and support to enhance comfort and well-being.</li>
                            <li>Transformative journey to healing, with personalized plans designed to restore wellness and improve quality of life.</li>
                        </ul>
                    </p>
                    </div>
            </div>
        </section>
        <CtaOne/>
        <Contact/>
        <Footer/>
        <ScrollTop/>
    </>
  )
}

export default Rehab