import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

export default function WhatsApp() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(true);
        }, 15000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Link to="https://wa.me/919205071639" className="whatsapp text-center justify-content-center align-items-center" style={visible ? { display: 'block' } : { display: 'none' }} target='blank'>
            <IoLogoWhatsapp className="fea icon-lg" />
        </Link>
    );
}
