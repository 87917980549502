import React,{useState,useEffect} from "react";
import { Link, useLocation } from 'react-router-dom';
// import { Link as ScrollLink } from 'react-scroll';


// import logoDark from '../assets/images/logo-dark.png'
// import logoLight from '../assets/images/logo-light.png'
import logoDark from '../assets/images/logoDark.png'
import logoLight from '../assets/images/logoLight.png'
// import dr1 from '../assets/images/doctors/01.jpg'

// import Offcanvas from 'react-bootstrap/Offcanvas';
// import image from '../assets/images/mobile-app.svg'

// import {FiSettings, FiSearch,GrDashboard, LiaSignOutAltSolid, FiShoppingCart, FiDribbble,RiBehanceLine, FaFacebookF,FiInstagram, FiTwitter,LuMail, LuGlobe} from '../assets/icons/vander'
import StickyVisitBtn from "../pages/calendlyStickyVisitBtn";
import WhatsApp from "./whatsApp";
// import { appointment } from './../data/data';

export default function Navbar({navDark, manuClass,containerClass}){
    let [show, setShow] = useState(false);
    let [showTwo, setShowTwo] = useState(false);
    let [scroll, setScroll] = useState(false);
    let [isMenu, setisMenu] = useState(false);
    let [modal, setModal] = useState(false)

    let handleClose = () => setShow(false);
    let handleShow = () => setShow(true);


    let handleCloseTwo = () => setShowTwo(false);
    let handleShowTwo = () => setShowTwo(true);


    let [manu , setManu] = useState('');
    let location = useLocation();

    useEffect(() => {

        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 50);
        });
        window.scrollTo(0, 0);
        const closeModal = ()=>{
            setModal(false)
        }
        document.addEventListener("mousedown",closeModal)
        return()=>{
            document.removeEventListener("mousedown",closeModal)
        }
      }, []);

      let toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };
    return(
        <header id="topnav" className={`${scroll ? "nav-sticky" :""} navigation sticky`}>
            <div className={containerClass}>
                <div>
                    {navDark === true ? 
                    <Link className="logo" to="/">
                        <img src={logoDark} height="48" className="logo-light-mode" alt=""/>
                        <img src={logoLight} height="48" className="logo-dark-mode" alt=""/>
                    </Link> :

                    <Link className="logo" to="/">
                        <span className="logo-light-mode">
                            <img src={logoDark} className="l-dark" height="48" alt=""/>
                            <img src={logoLight} className="l-light" height="48" alt=""/>
                        </span>
                        <img src={logoLight} height="48" className="logo-dark-mode" alt=""/>
                    </Link>
                    }
                </div>
        
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="/"  className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>


                <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                    <ul className={manuClass}>
                        <li className={`${["", "index","index-two", "index-three"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/">Home</Link><span className=""></span>
                        </li>

                        <li className={`${["doctor-dashboard", "doctor-appointment","patient-list", "doctor-schedule","invoices","patient-review","doctor-messages","doctor-profile","doctor-profile-setting","doctor-chat","login","signup","forgot-password","doctor-team-one","doctor-team-two","doctor-team-three"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                            <Link to="/aboutus">About Us</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li className={manu === "doctor-team-one" ? "active" : ""}><Link to="/aboutus/about" className="sub-menu-item">About Us</Link></li>
                                <li className={manu === "doctor-team-one" ? "active" : ""}><Link to="/aboutus/our-offerings" className="sub-menu-item">Our Offerings</Link></li>
                                <li className={manu === "doctor-team-three" ? "active" : ""}><Link to="/aboutus/mission" className="sub-menu-item">Mission & Vission</Link></li>
                                <li className={manu === "doctor-team-two" ? "active" : ""}><Link to="/aboutus/doctor-profile" className="sub-menu-item">Doctor's Profile</Link></li>
                                <li className={manu === "doctor-team-three" ? "active" : ""}><Link to="/aboutus/services" className="sub-menu-item">Our Services</Link></li>
                            </ul>
                        </li>

                        <li className={`${["patient-dashboard", "patient-profile","booking-appointment", "patient-invoice"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/knowledge-center">Knowledge Center</Link><span className=""></span>
                            {/* <ul className="submenu">
                                <li className={manu === "patient-dashboard" ? "active" : ""}><Link to="/patient-dashboard" className="sub-menu-item">Dashboard</Link></li>
                                <li className={manu === "patient-profile" ? "active" : ""}><Link to="/patient-profile" className="sub-menu-item">Profile</Link></li>
                                <li className={manu === "booking-appointment" ? "active" : ""}><Link to="/booking-appointment" className="sub-menu-item">Book Appointment</Link></li>
                                <li className={manu === "patient-invoice" ? "active" : ""}><Link to="/patient-invoice" className="sub-menu-item">Invoice</Link></li>
                            </ul> */}
                        </li>

                        <li className={`${["pharmacy", "pharmacy-shop","pharmacy-product-detail", "pharmacy-shop-cart","pharmacy-checkout","pharmacy-account"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/services">Services</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li className={manu === "pharmacy" ? "active" : ""}><Link to="/home-care" className="sub-menu-item">Home Care</Link></li>
                                <li className={manu === "pharmacy-shop" ? "active" : ""}><Link to="/elderly-care" className="sub-menu-item">Elderly Care</Link></li>
                                <li className={manu === "pharmacy-product-detail" ? "active" : ""}><Link to="/rehab-palliation" className="sub-menu-item">Rehab & Palliation</Link></li>
                                <li className={manu === "pharmacy-shop-cart" ? "active" : ""}><Link to="/consultancy" className="sub-menu-item">Consultation</Link></li>
                                <li className={manu === "pharmacy-checkout" ? "active" : ""}><Link to="/elderly-issues" className="sub-menu-item">Elderly Issues</Link></li>
                                {/* <li  className={manu === "pharmacy-account" ? "active" : ""}><Link to="/pharmacy-account" className="sub-menu-item">Account</Link></li> */}
                            </ul>
                        </li>

                        <li className={`${["aboutus", "departments","faqs", "blogs","blog-detail","terms","privacy","error","contact"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}><Link to="">Book Appointment</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li className={manu === "aboutus" ? "active" : ""}><Link to="/index/online-appointment" className="sub-menu-item">Book Online Appointment</Link></li>
                                <li className={manu === "departments" ? "active" : ""}><Link to="/index/contact" className="sub-menu-item">Our Clinic Location</Link></li>
                                <li className={manu === "faqs" ? "active" : ""}>
                                    <a href="tel:+919205071639" className="sub-menu-item">Call Us</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <StickyVisitBtn/>
                <WhatsApp/>
            </div>
        </header>
    )
}