import React from 'react'
import { useEffect } from 'react';
import Lottie from "lottie-react";
import shareAnimation from "../assets/images/reviews.json";



function Review() {

    // for inserting google review from elfsight start
    useEffect(() => {
        const script = document.createElement('script');
        script.src="https://static.elfsight.com/platform/platform.js";
        script.setAttribute('data-use-service-core', '');
        script.defer = true;
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    //   for inserting google review from elfsight end
      
    return (

        <>

            <section className="section" style={{background: 'var(--light-purple)'}}>
                <div className="container">
                    <div className="row align-items-center">
                    <div className="section-title">
                            <span className="badge bg-soft-primary p-3 fs-6">Testimonial</span>
                        </div>
                            <h5 className="mt-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h2 className="heading mb-3">Chekout Our Google Reviews</h2>
                                <Lottie animationData={shareAnimation} className='shareSize' speed='2.0' />
                        <div className="col">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                <div className="elfsight-app-57d0121f-a09e-442e-adff-a5ff56482279" data-elfsight-app-lazy></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>

    )
}

export default Review