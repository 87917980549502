import React from 'react';
import { Link } from "react-router-dom";
import heroAbout from '../assets/images/about/heroAbout.webp';


function AboutSmall() {
  return (
    <>
        <section className="section" style={{background: 'var(--white)'}}>
            <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-6">
                        <div className="section-title me-lg-5">
                            <span className="badge bg-soft-primary p-3 fs-6">About Chirayu Wellness Center</span>
                            <h4 className="heading mb-3 mt-3">Guiding Your Health Journey,<br/> One Step at a Time</h4>
                            <p className="para-desc text-muted">At Chirayu Wellness Center, we believe in the fundamental role of a General Physician in nurturing your health journey. Our approach is rooted in personalized care, guiding you towards optimal well-being with compassion and expertise.</p>
                            <p className="para-desc text-muted">In today's fast-paced world, it's easy to overlook the importance of basic medical alignments and rush to multi-specialty hospitals for every concern. However, we understand that sometimes, all you need is the right guidance from a trusted medical professional. <br/>Our commitment goes <b>beyond corporate structures and revenue targets.</b> Here, your health is our priority.</p>
                            <div className="mt-4">
                                <Link to="/aboutus" className="btn btn-soft-primary">Learn More About Us</Link>
                            </div>
                        </div>
                    </div>
                    <img src={heroAbout} className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0" alt=""/>
                </div>
            </div>
        </section>
    </>
  )
}

export default AboutSmall