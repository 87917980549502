import React from 'react'
import Banner from "../components/banner";
import Navbar from "../components/navbar";
import heroHomeServices from '../assets/images/banner/heroHomeServices.webp'
import heroImag from '../assets/images/hero.jpg'
import serviceCoverage from '../assets/images/banner/serviceCoverage.webp'
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import CtaOne from '../components/cta/ctaOne';
import Contact from './contact';
import CurveSlight from '../components/features/curveSlight';
import { Helmet } from "react-helmet";
// import Banner from "../components/banner";



function Homecare() {
  return (
    <>
    <Helmet>
      <title>Home Health Services | Quality Healthcare at Your Doorstep | Chirayu Wellness Center</title>
      <meta name="description" content="Doctor for home visit in Dehradun"/>
      <meta property="og:title" content="Home Health Services | Quality Healthcare at Your Doorstep | Chirayu Wellness Center"/>
      <meta property="og:description" content="Doctor for home visit in Dehradun."/>
      <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
      <meta property="og:url" content="https://drmayankdabral.com/home-care"/>
    </Helmet>
     <Navbar manuClass="navigation-menu nav-right nav-light" containerClass="container"/>
    <Banner 
        bgImage={heroHomeServices}
        title="Convenient Home Medical Care"
        description="Expert Care Delivered to Your Doorstep"
        overlayClassName="bg-overlay"
        titleClassName=""
     />
     <CurveSlight />
            <section className="bg-hero pb-0 d-table w-100" style={{marginBottom: '80px'}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="heading-title">
                            <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h4 className="heading mb-3" style={{color: '#5C483F'}}>Bringing Quality Healthcare at your Doorstep</h4>
                            <p className="para-desc text-muted mb-0 fs-5">Experience the convenience and comfort of healthcare tailored to your needs with our Home Health Services. Our dedicated team brings personalized health assessments and medication delivery directly to your home, ensuring you receive the care you need without the hassle of travel or clinic visits. With our commitment to accessibility and convenience, you can enjoy peace of mind knowing that expert healthcare is just a doorstep away.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={heroImag} className="img-fluid" alt=""/>
                    </div>
                </div>
                    <div className="col-12 mt-100">
                    <h4 className="mb-3" style={{color: '#5C483F'}}>Our Key features</h4>
                    <p className="para-desc text-muted mb-0 fs-5" >
                        <ul style={{textAlign: 'start'}}>
                            <li >Receive personalized health assessments and consultations in the comfort of your own home.</li>
                            <li>Access to a wide range of medical services, including diagnostic tests, medication delivery, and wound care.</li>
                            <li>Enjoy the convenience of healthcare services without the need for travel or clinic visits.</li>
                            <li>Expert guidance and support from our compassionate team of healthcare professionals.</li>
                            <li>Tailored care plans designed to meet your individual needs and promote overall well-being.</li>
                        </ul>
                    </p>
                    </div>
                    <div className="row mt-5 align-items-center">
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={serviceCoverage} className="img-fluid" alt=""/>
                    </div>
                    <div className="col-md-6">
                        <div className="heading-title">
                            {/* <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5> */}
                            <h4 className="mb-3" style={{color: '#5C483F'}}>Our Service Area</h4>
                            <p className="para-desc text-muted mb-0 fs-5">
                                <ul>
                                    <li>We are serving within 3 km radius of our clinic.</li>
                                    <li>For further details, reach out to us via WhatsApp.</li>
                                    <li>Home visit charges are different than in-clinic consultation</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <CtaOne/>
        <Contact />
        <Footer/>
        <ScrollTop/>
    </>
  )
}

export default Homecare