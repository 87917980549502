import React from 'react'
import Banner from "../components/banner";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import heroImag from '../assets/images/hero.jpg';
import Contact from './contact';
import CurveSlight from '../components/features/curveSlight';

import { Helmet } from "react-helmet";
import CtaOne from '../components/cta/ctaOne';



function ElderlyCare() {
  return (
    <>
        <Helmet>
            <title>Elderly Care Services in Dehradun | Tailored Support for Seniors | Chirayu Wellness Center</title>
            <meta name="description" content="Elderly Care Services in Dehradun"/>
            <meta name="keywords" content="Elderly Care Services, Tailored Support, Seniors, Bedridden Patients, Chronic Illness Management, Post-Surgery Recovery, Quality of for Senior Citizens, Chirayu Wellness Center"/>
            <meta property="og:title" content="Elderly Care Services | Tailored Support for Seniors | Chirayu Wellness Center"/>
            <meta property="og:description" content="Elderly Care Services in Dehradun"/>
            <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
            <meta property="og:url" content="https://drmayankdabral.com/elderly-care"/>
        </Helmet>
     <Navbar manuClass="navigation-menu nav-right nav-light" containerClass="container"/>
    <Banner 
        bgImage={heroImag}
        title="Compassionate Care for Seniors"
        description="Providing Medical Support to Your Families in Dehradun"
     />
     <CurveSlight />
            <section className="bg-hero pb-0 d-table w-100" style={{marginBottom: '80px'}}>
            <div className="container">
                <div className="row mt-sm-0 align-items-center">
                    <div className="col-md-6">
                        <div className="heading-title">
                            <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h4 className="heading mb-3" style={{color: '#5C483F'}}>Tailored Support for Seniors</h4>
                            <p className="para-desc text-muted mb-0 fs-5">Welcome to our Elderly Care Services, where we provide dedicated support tailored to the unique needs of seniors and bedridden patients. Our compassionate team offers comprehensive assistance, ensuring dignity, comfort, and well-being for those in need of specialized care.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={heroImag} className="img-fluid" alt=""/>
                    </div>
                </div>
                    <div className="col-12 mt-100">
                    <h4 className="mb-3" style={{color: '#5C483F'}}>Our Key features</h4>
                    <p className="para-desc text-muted mb-0 fs-5">
                        <ul>
                            <li>Tailored care plans designed specifically for seniors and bedridden patients, addressing their individual needs and preferences.</li>
                            <li>Comprehensive assistance with chronic illness management, including medication management, symptom monitoring, and coordination of care with healthcare providers.</li>
                            <li>Support for post-surgery recovery, offering guidance, assistance, and rehabilitation exercises to promote healing and regain mobility.</li>
                            <li>Emphasis on preserving dignity and ensuring comfort, with a focus on maintaining independence and quality of life for elderly individuals.</li>
                            <li>Compassionate and skilled care provided by our experienced team, dedicated to enhancing the well-being of seniors and bedridden patients.</li>
                        </ul>
                    </p>
                    </div>
            </div>
        </section>
        <CtaOne/>
        <Contact/>
        <Footer/>
        <ScrollTop/>
    </>
  )
}

export default ElderlyCare