import React from 'react'
import Banner from "../components/banner";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import heroImag from '../assets/images/banner/consultancysection.webp';
import heroConsultant from '../assets/images/banner/medicalconsultation.webp';
import Contact from './contact';
import CurveSlight from '../components/features/curveSlight';
import { Helmet } from "react-helmet";
import CtaOne from '../components/cta/ctaOne';


function Consultancy() {
  return (
    <>
        <Helmet>
            <title>Doctor Consultation | Personalized Treatment Plans | Chirayu Wellness Center</title>
            <meta name="description" content="Explore a second medical opinion with our independent medical consultation."/>
            <meta property="og:title" content="Doctor Consultation | Personalized Treatment Plans | Chirayu Wellness Center"/>
            <meta property="og:description" content="Explore a second medical opinion with our independent medical consultation."/>
            <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
            <meta property="og:url" content="https://drmayankdabral.com/consultancy"/>
        </Helmet>
     <Navbar manuClass="navigation-menu nav-right nav-light" containerClass="container"/>
    <Banner 
        bgImage={heroConsultant}
        title="Guiding Your Health Journey: Consultation with Healthcare Professional"
        description="Personalized Treatment Plans in Dehradun"
        overlayClassName= "bg-overlay"
        titleClassName= ""
        />
        <CurveSlight/>
            <section className="bg-hero pb-0 d-table w-100" style={{marginBottom: '80px'}}>
            <div className="container">
                <div className="row mt-sm-0 align-items-center">
                    <div className="col-md-6">
                        <div className="heading-title">
                            <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h4 className="heading mb-3" style={{color: '#5C483F'}}>Maximizing Treatment Outcomes: Your Path to Informed Decision-Making</h4>
                            <p className="para-desc text-muted mb-0 fs-5">Our experienced healthcare professionals provide expert guidance to optimize your treatment journey. Through personalized consultation, we offer tailored treatment plans, expert advice on medication selection, comprehensive guidance on investigations, and insights into medical devices and equipment. Our goal is to ensure optimal treatment outcomes and enhance your overall well-being through informed decision-making..</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={heroImag} className="img-fluid" alt=""/>
                    </div>
                </div>
                    <div className="col-12 mt-100">
                    <h4 className="mb-3" style={{color: '#5C483F'}}>Our Key features</h4>
                    <p className="para-desc text-muted mb-0 fs-5">
                        <ul>
                            <li>Personalized treatment plans designed to meet your unique healthcare needs and preferences.</li>
                            <li>Expert advice on medication selection, considering factors such as effectiveness, safety, and potential side effects.</li>
                            <li>Comprehensive guidance on investigations, including laboratory tests, imaging studies, and diagnostic procedures.</li>
                            <li>Insights into medical devices and equipment, helping you understand their purpose, usage, and benefits.</li>
                            <li>Ensuring optimal treatment outcomes through informed decision-making, empowering you to take control of your health journey.</li>
                        </ul>
                    </p>
                    </div>
            </div>
        </section>
        <CtaOne/>
        <Contact/>
        <Footer/>
        <ScrollTop/>
    </>
  )
}

export default Consultancy