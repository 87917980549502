import React from 'react';
import StickyNote from "../components/features/stickynote";
import heroImag from '../assets/images/hero.webp';

function HeroSection() {
  return (
    <>
            <section className="bg-half-170 pb-0 d-table w-100" >
            <div className="container">
                <div className="row mt-5 mt-sm-0 align-items-center" >
                    <div className="col-md-6">
                        <div className="sticky-container">
                            <StickyNote />
                        </div>
                        <div className="heading-title">
                            <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h1 className="heading mb-3">#1 General Physician in Dehradun</h1>
                            <p className="para-desc text-muted mb-0 fs-5">Cultivate your well-being and nurture your life's vitality at Chirayu Wellness Center. Book an appointment with Dr. Mayank Dabral to start your journey towards optimal health.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={heroImag} className="img-fluid" alt=""/>
                    </div>
                </div>
                
            </div>
            <div className="home-wave-bottom">
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
                </g>
                </svg>
            </div>
        </section>
    </>
  )
}

export default HeroSection