import React, { useState, useEffect } from 'react';
import pin from '../../assets/images/pin.png'

function StickyNote() {
  const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);
  return (
    <>
        <div  className="sticky-card-container" style={visible ? { display: 'block' } : { display: 'none' }}>
          <div className="sticky-card">
            <img src={pin} alt="pin"/>
            <h3>Consultation Charges</h3>
            <p className='new-price'>₹ 300</p>
            <h2>Book Your Appointment Now</h2>
          </div>
        </div>      
    </>
  );
}

export default StickyNote;

