import React, { useEffect, useRef   } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/banner";
import hero2 from '../assets/images/hero2.webp';
import CurveSlight from "../components/features/curveSlight";
import heroAbout from '../assets/images/about/heroAbout.webp';



import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import ServicesOne from "../components/features/servicesOne";
import DoctorBioData from "../components/doctorBioData";
import ServicesSpanshot from "../components/servicesSpanshot";
import Contact from "./contact";
import CtaTwo from "../components/cta/ctaTwo";
import { Helmet } from "react-helmet";



export default function AboutUs(){
    const { section } = useParams();
    const sectionRef = useRef(null);

    useEffect(() => {
        if (section) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
            // Scroll to the section if it exists
            sectionElement.scrollIntoView({ behavior: "smooth" });
        }
        } else {
        // Scroll to the top of the page if no section is specified
        window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [section]);


    return(
        <>
            <Helmet>
                <title>Chirayu Wellness Center | About Us</title>
                <meta name="description" content="Discover our personalized healthcare approach. Meet Dr. Mayank Dabral and explore our services."/>
                <meta name="keywords" content=" Chirayu Wellness Center, About us, Dehradun, personalized healthcare, medical symptoms, chronic conditions, preventive care, holistic approach, Dr. Mayank Dabral"/>
                <meta property="og:title" content="Chirayu Wellness Center | About Us"/>
                <meta property="og:description" content="Discover our personalized healthcare approach. Meet Dr. Mayank Dabral and explore our services."/>
                <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
                <meta property="og:url" content="https://drmayankdabral.com/aboutus"/>
                <link rel="canonical" href="https://drmayankdabral.com/aboutus"/>

            </Helmet>
            <Navbar manuClass="navigation-menu nav-light nav-right" containerClass="container"/>
            <section id="heroSection">
                <Banner 
                    bgImage={hero2}
                    title="Empowering Healthier Choices"
                    description="Providing support and resources needed for individuals to make informed decisions that lead to improved well being and vitality."
                    overlayClassName=""
                    titleClassName=""
                />
                <CurveSlight/>
            </section>
            <section id="about" className="section">
            <div className="container">
                <div className="row align-items-center">
                    <img src={heroAbout} className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0" alt=""/>
                    <div className="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                        <div className="ms-lg-4">
                            <div className="section-title me-lg-5">
                                <span className="badge bg-soft-primary p-3 fs-5">About Chirayu Wellness Center</span>
                                <h4 className="title mt-3 mb-4">Guiding Your Health Journey,<br/> One Step at a Time</h4>
                                <p className="para-desc text-muted">At Chirayu Wellness Center, we believe in the fundamental role of a General Physician in nurturing your health journey. Our approach is rooted in personalized care, guiding you towards optimal well-being with compassion and expertise.</p>
                                <p className="para-desc text-muted">In today's fast-paced world, it's easy to overlook the importance of basic medical alignments and rush to multi-specialty hospitals for every concern. However, we understand that sometimes, all you need is the right guidance from a trusted medical professional.<b> Our commitment goes beyond corporate structures and revenue targets</b>. Here, your health is our priority.</p>
                                <p className="para-desc text-muted">Our experienced team at Chirayu Wellness Center specializes in addressing a wide range of common medical symptoms. From managing chronic conditions like diabetes, hypertension, and arthritis to treating acute ailments such as respiratory tract infections, asthma, and bronchitis, we offer comprehensive care tailored to your individual needs.</p>
                                <p className="para-desc text-muted">We understand the impact that these conditions can have on your daily life, which is why we strive to provide timely and effective treatment options. Whether you're experiencing symptoms of COPD, the common cold, or gastroenteritis, our team is here to help alleviate discomfort and restore your well-being.</p>
                                <p className="para-desc text-muted">Additionally, we prioritize preventive care measures to address concerns like fatty liver, raised cholesterol, and spondylitis before they escalate. Through patient education and lifestyle modifications, we empower you to take control of your health and reduce the risk of future complications.</p>
                                <p className="para-desc text-muted">Our holistic approach extends to the management of urinary tract infections, gout, and hypothyroidism, where we focus on addressing underlying causes and promoting overall wellness.</p>
                                <p className="para-desc text-muted">At Chirayu Wellness Center, your health is in good hands. Contact us today to schedule a consultation with Dr. Mayank Dabral and take the next step towards a healthier, happier life.</p>
                                {/* <div className="mt-4">
                                    <Link to="" className="btn btn-soft-primary">Read More</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="doctor-profile">
                <DoctorBioData />
            </section>
            <section id="our-offerings">
                <ServicesOne />
            </section>
            <section id="mission">
                <CtaTwo />
            </section>
            <section id="services">
                <ServicesSpanshot />
            </section>
        </section>
        <section id="contact">
            <Contact />
            <Footer/>
            <ScrollTop/>
        </section>
        </>
    )
}