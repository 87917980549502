import React from 'react';
import { doctorData } from "../data/data";
import { Helmet } from 'react-helmet';

const startDate = '2004-05-01'; // Replace with actual start date
const yearsOfExperience = calculateExperience(startDate);
function calculateExperience(startDate) {
    const today = new Date();
    const start = new Date(startDate);
    const diff = today - start;
    const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    return years;
  }

function DoctorBioData() {
  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://drmayankdabral.com/aboutus/doctor-profile"/>
    </Helmet>
                <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            {/* <h2 className="title mb-4">Your Doctor</h2> */}
                            <h1 className="mb-3 fs-3" style={{color: '#000000'}}>Our Team</h1>
                            <p className="text-muted mx-auto para-desc mb-0"><b>Dr. Mayank Dabral</b> brings <b>{yearsOfExperience}</b> years of experience in patient management and public health research, with distinguished education from renowned institutions worldwide.</p>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center border-0 rounded shadow">
                    {doctorData.slice(0,1).map((item, index) =>{
                        return(
                        <>
                            <div className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                                <div className="card team border-0 rounded shadow overflow-hidden">
                                    <div className="team-img position-relative">
                                        <img src={item.image} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="card-body content text-center">
                                        <div className="title text-dark h5 d-block mb-0">{item.name}</div>
                                        <small className="text-muted speciality">{item.speciality}</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-6 mt-4 pt-2" key={index}>
                                <div className="overflow-hidden">
                                    <div className="card-body content text-start p-5">
                                        <h2 className="mb-3">Dr. Mayank Dabral</h2>
                                        <h5 className="text-muted speciality">M.B.B.S. / M.P.H</h5>
                                        <h3 className="border-bottom mb-3">Experience</h3>
                                        <h5 className="text-muted speciality">{item.desc6}</h5>
                                        <h5 className="text-muted speciality">{item.desc5}</h5>
                                        <h5 className="text-muted speciality">{item.desc7}</h5>
                                        <h5 className="text-muted speciality">{item.desc8}</h5>
                                        <h5 className="text-muted speciality">{item.desc9}</h5>
                                        <h3 className="border-bottom mt-5 mb-3">Education</h3>
                                        <h4 className="title text-dark h5 d-block mb-0">{item.desc1}</h4>
                                        <h5 className="text-muted speciality">{item.desc2}</h5>
                                        <h4 className="title text-dark h5 d-block mb-0">{item.desc3}</h4>
                                        <h5 className="text-muted speciality">{item.desc4}</h5>
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                    })}
                </div>
            </div>
    </>
  )
}

export default DoctorBioData