import React from 'react';
// import accordionImage from '../../assets/images/svg/vaccine-development-amico.svg';
// import accordionImage from '../assets/images/svg/vaccine-development-amico.svg';
import faqAnnimation from '../assets/images/faqAnnimation1.json'
import AccordionOne from '../components/accordion/accordionOne';
import Lottie from 'lottie-react';
// import { accordionData } from './../data/data';

function FaqSection() {
  return (
    <>
    <section className="section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 col-12 my-3 my-sm-3">
                    <div className="">
                        <div className="section-title">
                        </div>
                        <Lottie animationData ={faqAnnimation} speed='2.0' />
                        {/* <img src={accordionImage} className="img-fluid" alt=""/> */}
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <AccordionOne  />
                </div>
            </div>
            <span className="badge bg-soft-primary p-3 fs-6 faq-badge">Frequently Asked Questions</span>
        </div>
    </section>
    </>
  )
}

export default FaqSection