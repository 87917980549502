import React from 'react'
import Banner from "../components/banner";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import heroImag from '../assets/images/banner/heroelderlyissues.webp';
import sectionImage from '../assets/images/banner/sectionelderlyissues.webp';
import Contact from './contact';
import CurveSlight from '../components/features/curveSlight';
import { Helmet } from "react-helmet";
import CtaOne from '../components/cta/ctaOne';


function ElderlyIssues() {
  return (
    <>
     <Helmet>
      <title>Elderly Health Issues Management | Tailored Care for Seniors | Chirayu Wellness Center</title>
      <meta name="description" content="Expert management of common health issues like diabetes, hypertension, arthritis, and respiratory tract infections affecting seniors."/>
      <meta property="og:title" content="Elderly Health Issues Management | Tailored Care for Seniors | Chirayu Wellness Center"/>
      <meta property="og:description" content="Expert management of common health issues like diabetes, hypertension, arthritis, and respiratory tract infections affecting seniors."/>
      <meta property="og:image" content="%PUBLIC_URL%/android-chrome-512x512.png"/>
      <meta property="og:url" content="https://drmayankdabral.com/elderly-issues"/>
    </Helmet>
     <Navbar manuClass="navigation-menu nav-right nav-light" containerClass="container"/>
    <Banner 
        bgImage={heroImag}
        title="Enhancing Quality of Life: Tailored Solutions for Elderly Ailments"
        description="Expert Management of Common Elderly Health Issues"
        overlayClassName= "bg-overlay"
        titleClassName= ""
     />
     <CurveSlight />
            <section className="bg-hero pb-0 d-table w-100" style={{marginBottom: '80px'}}>
            <div className="container">
                <div className="row mt-sm-0 align-items-center">
                    <div className="col-md-6">
                        <div className="heading-title">
                            <h5 className="mb-3 fs-4" style={{color: '#396CF0'}}>Chirayu Wellness Center</h5>
                            <h4 className="heading mb-3" style={{color: '#5C483F'}}>Specialized Support for Aging Well</h4>
                            <p className="para-desc text-muted mb-0 fs-5">We specialize in addressing common health issues that affect aging individuals. Our expert team offers comprehensive care for conditions such as diabetes, hypertension, arthritis, respiratory tract infections, asthma, bronchitis, COPD, and more, tailored to the specific needs of seniors. We understand the unique challenges faced by aging individuals and are dedicated to promoting their health and comfort through personalized care and support.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={sectionImage} className="img-fluid" alt=""/>
                    </div>
                </div>
                    <div className="col-12 mt-100">
                    <h4 className="mb-3" style={{color: '#5C483F'}}>Our Key features</h4>
                    <p className="para-desc text-muted mb-0 fs-5">
                        <ul>
                            <li>Expert management of common health issues prevalent in aging individuals, including diabetes, hypertension, arthritis, respiratory tract infections, and more.</li>
                            <li>Tailored care plans designed to address the specific needs and challenges of seniors, ensuring optimal management of their health conditions.</li>
                            <li>Comprehensive support for aging individuals and their families, providing compassionate care and guidance throughout the management process.</li>
                            <li>Promoting health, comfort, and independence in seniors through specialized care and personalized attention.</li>
                            <li>Empowering aging individuals to live fulfilling lives by addressing their health concerns with expertise and compassion.</li>
                        </ul>
                    </p>
                    </div>
            </div>
        </section>
        <CtaOne/>
        <Contact/>
        <Footer/>
        <ScrollTop/>
    </>
  )
}

export default ElderlyIssues