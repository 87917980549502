import React from "react";

import bg1 from '../../assets/images/cta.jpg'

import { ctaData } from "../../data/data";
import { Helmet } from "react-helmet";

export default function CtaTwo(){
    return(
        <>
        <Helmet>
            <link rel="canonical" href="https://drmayankdabral.com/aboutus/our-offerings"/>
        </Helmet>
        <section className="section" style={{backgroundImage:`url(${bg1})`}}>
            <div className="bg-overlay bg-overlay-dark" style={{opacity:'0.3'}}></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 offset-lg-7 col-md-7 offset-md-5">
                        {ctaData.map((item, index) =>{
                            let Icon = item.icon
                            return(
                                <div className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4 align-middle" key={index}>
                                    <div className="icons-container d-flex align-items-center">
                                        <Icon className="icons mb-0 text-primary display-2" />
                                    </div>
                                    <div className="ms-3">
                                        <h5 className="titles">{item.title}</h5>
                                        <p className="text-muted para mb-0">{item.desc}</p>
                                    </div>
                                    <div className="big-icon d-flex justify-content-center align-items-center">
                                        <Icon/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
                    <div className="position-relative">
                    <div className="shape overflow-hidden text-color-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
                </>
    )
}