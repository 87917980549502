import React from 'react';
import { medicalServices } from "../../data/data";
import { Helmet } from 'react-helmet';


function ServicesOne() {
  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://drmayankdabral.com/aboutus/our-offerings"/>
    </Helmet>
                <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12">
                            <span className="badge rounded-pill bg-soft-primary fs-5 mb-3 faq-badge p-3">Services</span>
                        <div className="section-title mb-4 pb-2 text-center pt-5">
                            <h4 className="title mb-4">Our Medical Services</h4>
                            <p className="text-muted mx-auto para-desc mb-0">Reliable medical center offering quick support, efficient services, or a basic consultation for your dear ones.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {medicalServices.slice(0,4).map((item, index) =>{
                        let Icon = item.icon
                        return(
                            <div className="col-xl-3 col-md-4 col-12 mt-4 pt-2" key={index}>
                                <div className="card features feature-primary border-0">
                                    <div className="text-center rounded-md align-self-center">
                                    <img src={item.image} className="" alt="icon" style={{height: '75px', width: '75px', padding: '5px'}}/>
                                    </div>
                                    <div className="card-body p-0 mt-3">
                                        <div className="title text-dark text-center h5">{item.title}</div>
                                        <p className="text-muted text-justify mt-3">{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

    </>
  )
}

export default ServicesOne