import React from "react";
import { Route, Routes} from "react-router-dom";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/style.scss'
import './assets/scss/bootstrap.scss'
import './assets/css/materialdesignicons.min.css'

import Index from "./pages/index";
import AboutUs from "./pages/aboutus";
import Privacy from "./pages/privacy";
import Error from "./pages/error";
import Contact from "./pages/contact";
import Homecare from "./pages/homecare"
import ElderlyCare from "./pages/elderlycare"
import Rehab from "./pages/rehab"
import Consultancy from "./pages/consultancy"
import ElderlyIssues from "./pages/elderlyIssues"
import Services from "./pages/services"
import KnowledgeCenter from "./pages/knowledgeCenter"


function App() {
  return (
    <Routes>
      <Route path="/" element={<Index/>}/>
      <Route path="/index" element={<Index/>}/>
      <Route path="/index/:section" element={<Index />} />
      <Route path="/aboutus" element={<AboutUs/>}/>
      <Route path="/aboutus/:section" element={<AboutUs />} />
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/error" element={<Error/>}/>
      <Route path="*" element={<Error/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/home-care" element ={<Homecare/>}/>
      <Route path="/elderly-care" element ={<ElderlyCare/>}/>
      <Route path="/rehab-palliation" element ={<Rehab/>}/>
      <Route path="/consultancy" element ={<Consultancy/>}/>
      <Route path="/elderly-issues" element ={<ElderlyIssues/>}/>
      <Route path="/services" element ={<Services/>}/>
      <Route path="/knowledge-center" element ={<KnowledgeCenter/>}/>
    </Routes>
  );
}

export default App;
