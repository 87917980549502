import React from 'react';
import { RiArrowRightLine} from '../assets/icons/vander';
import { category } from "../data/data";
import { Link } from "react-router-dom";


function ServicesSpanshot() {
  return (
    <>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-4">Specialized Care Services</h4>
                            <p className="text-muted mx-auto para-desc mb-0">Explore our range of specialized care services designed to meet the unique needs of our patients. From personalized home health visits to comprehensive support for the elderly and bedridden, we offer expert guidance and compassionate assistance. Our dedicated team provides tailored solutions for rehabilitation, palliative care, medication consultation, and management of common elderly issues. Experience the comfort and convenience of healthcare delivered with empathy and expertise.</p>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    {category.map((item, index) => {
                        let Icon = item.icon
                        return(
                            <div className="col-xl col-md-4 col-12 mt-4 pt-2" key={index}>
                                <div className="card features feature-primary border-0 p-4 rounded-md shadow justify-content-center">
                                    <div className="icon text-center rounded-lg align-self-center">
                                            <Icon className="h3 mb-0"/>
                                    </div>
                                    <div className="card-body p-0 mt-3">
                                        <div className="title text-dark h5">{item.title}</div>
                                        <p className="text-muted mt-3">{item.desc}</p>
                                        <Link to={item.path} className="link">Find here <RiArrowRightLine className="align-middle"/></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </section>
    </>
  )
}

export default ServicesSpanshot