import React from 'react';
import { Link } from "react-router-dom";
import "../../assets/css/custom.css";
import bg1 from '../../assets/images/bg/01.webp'
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
import CountUp from 'react-countup';
import { counterData } from '../../data/data';
import { Helmet } from 'react-helmet';

export default function CtaOne(){
    return(
        <>
        <Helmet>
            <link rel="canonical" href="https://drmayankdabral.com/"/>
        </Helmet>
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center" style={{paddingBottom: '5em'}} >
                        <div className="col-12 text-center">
                            <div className="video-solution-cta position-relative" style={{zIndex:'1'}}>
                                <div className="position-relative">
                                    <img src={bg1} className="img-fluid rounded-md shadow-lg" alt=""/>
                                    <div className="row align-items-center pt-100 py-3">
                                        <div className="col-sm-6 col-md-6 col-lg-6" id='text-col'>
                                        <h2 className="mt-5 text-white title-dark">Budget-Friendly Options</h2>
                                        <p className='mx-auto para-desc mb-0 text-white fs-4'>Quality care within your budget. Schedule your appointment here or call us and save time.</p>
                                            <Link type="button" className="btn-call my-4" onClick={() => window.location.href = 'tel:+919205071639'}>Call Now</Link>

                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 align-items-center">
                                            {counterData.map((item, index) => {
                                                return(
                                                    <div className="row" key={index}>
                                                        <div className="counter-box text-center">
                                                            <h2 className="text-white title-dark">{item.symbol}&nbsp;
                                                            <CountUp 
                                                                className="counter-value" 
                                                                start={item.initial} 
                                                                end={item.target} 
                                                                duration={5} 
                                                                useEasing={false}
                                                                separator=","
                                                            />
                                                            </h2>
                                                            <h5 className="counter-head text-white title-dark mb-1">{item.titleOne}</h5>
                                                            <p className="text-white-50 mb-0">{item.titleTwo}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="feature-posts-placeholder bg-primary" style={{paddingBlock: '600px !important'}}></div>
                </div>
            </section>
        </>
    )
}