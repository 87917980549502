import React from 'react';
import Navbar from '../components/navbar';
import Banner from '../components/banner';
import Youtube from './youtube';
import ServicesSpanshot from '../components/servicesSpanshot';
import FaqSection from '../components/faqSection';
import CtaTwo from '../components/cta/ctaTwo';
import Contact from './contact';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import CurveSlight from '../components/features/curveSlight';
import heroKnowledge from '../assets/images/banner/heroKnowledge.webp';
// import CtaOne from '../components/cta/ctaOne';


function KnowledgeCenter() {
  return (
    <>
    <Navbar manuClass="navigation-menu nav-light nav-right" containerClass="container"/>
            <section>
                <Banner 
                    bgImage={heroKnowledge}
                    title="Healthy Lifestyle Insights"
                    description="Inspiring Videos to Enhance Your Well-being"
                    overlayClassName="bg-overlay"
                    titleClassName=""
                    height={80}              
                />
                <CurveSlight/>
            </section>
    {/* <CtaTwo/> */}
    <Youtube/>
    <FaqSection/>
    <ServicesSpanshot/>
    <CtaTwo/>
    <Contact/>
    <Footer/>
    <ScrollTop/>
    </>
  )
}

export default KnowledgeCenter